<template>
	<S2SForm title="Organisations" cy-data="shops-view-container">
		<v-flex xs12 class="text-xs-left text-right pb-2">
			<v-btn class="ml-0" :block="$vuetify.breakpoint.xsOnly" color="accent" :to="{ name: 'shop-creation' }" cy-data="add-shop">Add Organisation</v-btn>
		</v-flex>

		<S2SCard>
			<v-text-field
				slot="toolbar-content"
				v-model="search"
				append-icon="search"
				label="Search by organisation"
				single-line
				class="mr-4 search"
				hide-details
				@keypress.enter="searchUser"
				@click:append="searchUser"
				clearable
				@click:clear="clearUser('search')"
			></v-text-field>
			<v-autocomplete
				slot="toolbar-content"
				v-model="selectedUser"
				:loading="searching"
				:items="searchedUsers"
				:search-input.sync="emailSearch"
				cache-items
				hide-no-data
				label="Search by user email"
				item-text="email"
				return-object
				hide-details
				clearable
				class="mr-4 email"
				@keyup="searchUser"
				@click:clear="clearUser('selectedUser')"
			></v-autocomplete>
			<v-select
				slot="toolbar-content"
				v-model="type"
				:items="types"
				label="Filter Organisation Type"
				item-text="name"
				item-value="id"
				hide-details
				clearable
				@change="searchByType"
				@click:clear="clearUser('type')"
			></v-select>

			<v-select label="Rows per page" v-model="itemsPerPage" :items="rowsPerPage"></v-select>

			<v-data-table
				ref="dataTable"
				:headers="headers"
				:items="organisations"
				:search="search"
				:loading="loading"
				:server-items-length="pagination.totalItems"
				hide-default-footer
				class="elevation-1"
				@click:row="onRowClick($event)"
				@update:options="paginate($event)"
				@page-count="pageCount = $event"
			></v-data-table>

			<div v-if="pagination.totalItems" class="text-right mt-3">
				<v-chip class="my-2">
					{{ itemsPerPage * (pagination.page - 1) + 1 }} -
					{{ itemsPerPage * pagination.page > pagination.totalItems ? pagination.totalItems : itemsPerPage * pagination.page }} /
					{{ pagination.totalItems }}
				</v-chip>
				<v-pagination v-model="page" :length="Math.ceil(pageCount)"></v-pagination>
			</div>
		</S2SCard>
	</S2SForm>
</template>

<script>
import { LookupsApi } from "@/api/lookups-api";

export default {
	name: "Shops",

	computed: {
		pagination: {
			get() {
				return this.$store.state.organisations.pagination;
			},
			set(value) {
				this.$store.dispatch("organisations/paginate", value);
			}
		},
		organisations: function() {
			return this.$store.state.organisations.organisations;
		},
		loading: function() {
			return this.$store.state.organisations.loading;
		},
		searchedUsers: function() {
			return this.$store.state.organisations.users.searchedUsers;
		},
		searching: function() {
			return this.$store.state.organisations.users.searching;
		}
	},

	watch: {
		selectedUser(user) {
			if (user != null) {
				this.email = user.email;

				// search by name on pagination
				this.paginate({ ...this.pagination, page: 1 });
			}
		},

		page(value) {
			this.paginate({ ...this.pagination, page: value });
		},

		itemsPerPage(value) {
			//this.page = 1;
			this.paginate({ ...this.pagination, page: 1, itemsPerPage: value });
			this.pageCount = this.pagination.totalItems / value;
		}
	},

	data: function() {
		return {
			types: [],
			type: null,
			search: "",
			email: "",
			emailSearch: "",
			selectedUser: null,
			headers: [
				{ text: "ID", value: "id", align: "left", sortable: false },
				{ text: "Code", value: "code", align: "left", sortable: false },
				{ text: "Name", value: "name", align: "left", sortable: false },
				{ text: "Types", value: "types.0.name", align: "left", sortable: false }
			],
			page: 1,
			pageCount: 10,
			rowsPerPage: [
				{ text: "10", value: 10 },
				{ text: "25", value: 25 },
				{ text: "50", value: 50 }
			],
			itemsPerPage: 10
		};
	},

	async mounted() {
		const response = await LookupsApi.fetchOrgTypes();
		this.types = response.data.results;
	},

	methods: {
		paginate(pagination) {
			const fetchOrgs = this.search !== "" || this.email !== "" || this.type != null;

			this.$store.dispatch("organisations/paginate", pagination);

			if (fetchOrgs) {
				this.$store.dispatch("organisations/fetchOrganisations", {
					pagination: pagination,
					params: { search: this.search, email: this.email, type: this.type }
				});
			}
		},

		clearUser(fieldToClear) {
			switch (fieldToClear) {
				case "search":
					this.search = "";
					break;

				case "selectedUser":
					this.email = "";
					break;

				case "type":
					this.type = null;
					break;
			}

			const clearData = (this.search == null || this.search === "") && this.email === "" && this.type == null;

			if (clearData) {
				// clear data
				this.$store.commit("organisations/setOrganisations", []);
				this.$store.commit("organisations/setTotal", 0);
			} else {
				this.paginate(this.pagination);
			}
		},

		searchUser(event) {
			const searchName = event.target.parentNode.parentNode.parentNode.parentNode.classList.contains("search");
			const searchEmail = event.target.parentNode.parentNode.parentNode.parentNode.classList.contains("email");
			const value = event.target.value;

			if (searchName) {
				// search by name on pagination
				this.paginate(this.pagination);
			}

			if (searchEmail) {
				// search by email
				this.$store.dispatch("organisations/users/searchUsersByEmail", value);
			}
		},

		searchByType() {
			const clearData = this.search == null && this.email === "" && this.type == null;

			if (clearData) {
				this.clearUser();
			} else {
				// search by type on pagination
				this.paginate(this.pagination);
			}
		},

		isUnique(objArray = [], key, value) {
			for (let i = 0; i < objArray.length; i++) {
				if (objArray[i][key] === value) return false;
			}
			return true;
		},
		setRecentOrgsToLocalStorage(item) {
			let recentOrgs = JSON.parse(localStorage.getItem("recent-organisations") || "[]");
			if (recentOrgs.length < 1) {
				recentOrgs = [{ id: item.id, name: item.name }];
			} else if (this.isUnique(recentOrgs, "id", item.id)) {
				recentOrgs.unshift({ id: item.id, name: item.name });
				if (recentOrgs.length > 5) recentOrgs.pop();
			}

			localStorage.setItem("recent-organisations", JSON.stringify(recentOrgs));
		},
		onRowClick(item) {
			this.setRecentOrgsToLocalStorage(item);
			this.$router.push({ name: "organisation-profile", params: { orgId: "" + item.id } });
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
